import React, { Component } from 'react';
import Ajax from '../common/ajax';
import Config from '../config';
import Col from '../common/containers/Col';
import Row from '../common/containers/Row';
import Waiting from '../common/containers/Waiting';
import YoutubeVideo from './YoutubeVideo';

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: {},
      currentVideoSrc: null,
      processing: false,
    };
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchVideos();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchVideos() {
    this.setState((state) => ({ ...state, processing: true }));
    Ajax.get(`${Config.apiHost}help/categorized-videos`).done((videos) => {
      this._mounted && this.setState((state) => ({ ...state, videos, processing: false }));
    });
  }

  render() {
    const categories = Object.keys(this.state.videos);

    return (
      <Waiting isProcessing={this.state.processing}>
        <ul className="nav nav-tabs" role="tablist">
          {categories.map((category, idx) => {
            return (
              <li key={idx} role="presentation" className={idx === 0 ? 'active' : ''}>
                <a href={`#${idx}`} aria-controls={idx} role="tab" data-toggle="tab">
                  {category}
                </a>
              </li>
            );
          })}
        </ul>

        <div className="tab-content">
          {categories.map((category, idx) => {
            const videos = [...this.state.videos[category]];

            let subLists = [];
            while (videos.length > 0) {
              subLists.push(videos.splice(0, 4));
            }

            return (
              <div key={idx} role="tabpanel" className={`tab-pane ${idx === 0 ? 'active' : ''}`} id={idx}>
                {subLists.map((videosList, idx) => (
                  <Row key={idx}>
                    {videosList.map((video) => (
                      <Col key={video.snippet.resourceId.videoId} md={3}>
                        <YoutubeVideo video={video} showThumbnail={true} />
                      </Col>
                    ))}
                  </Row>
                ))}
              </div>
            );
          })}
        </div>
      </Waiting>
    );
  }
}

export default Videos;
